<template>
    <v-container>
        <div v-if="loading" class="d-flex flex-column align-center justify-center">
            <v-progress-linear indeterminate color="info" />
            <h3>Carregando avaliação Técnica</h3>
        </div>
        <div v-else class="overflow-y-auto secondary rounded" style="height: 45em">
            <v-card v-for="technicalEvaluation in technicalEvaluations" :key="technicalEvaluation.uuid" class="my-6 mx-6">
                <v-card-text>
                    <v-row>
                        <v-col order="2" order-sm="2" order-md="1" cols="12" sm="12" md="6">
                            <v-row>
                                <!-- Verbo & Complemento -->
                                <v-col cols="12">
                                    <div class="d-flex flex-column flex-md-row align-center">
                                        <v-list>
                                            <v-list-item two-line class="px-1">
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Verbo</v-list-item-subtitle>
                                                    <v-list-item-title
                                                        v-if="technicalEvaluation && technicalEvaluation.verbo"
                                                        class="font-weight-medium text-h6">{{ technicalEvaluation.verbo
                                                        }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-list>
                                            <v-list-item two-line class="px-1">
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Complemento</v-list-item-subtitle>
                                                    <v-list-item-title
                                                        v-if="technicalEvaluation && technicalEvaluation.complemento"
                                                        class="font-weight-medium text-h6">{{
                                                            technicalEvaluation.complemento
                                                        }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-col>
                                <!-- Melhor_se & Objetivo -->
                                <v-col cols="12" class="mt-0 pt-0">
                                    <div class="d-flex flex-column flex-md-row align-center">
                                        <v-list class="pt-0">
                                            <v-list-item two-line class="px-1 pt-0">
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Melhor Se</v-list-item-subtitle>
                                                    <v-list-item-title
                                                        v-if="technicalEvaluation && technicalEvaluation.melhor_se"
                                                        class="font-weight-regular">{{ technicalEvaluation.melhor_se |
                                                            capitalize()
                                                        }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-list class="pt-0">
                                            <v-list-item two-line class="px-1 pt-0">
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Objetivo Geral</v-list-item-subtitle>
                                                    <v-list-item-title class="font-weight-regular">{{
                                                        technicalEvaluation.objetivo |
                                                        processFloat(technicalEvaluation.tipo_unidade_medida)
                                                    }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col order="1" order-sm="1" order-md="2" cols="12" sm="12" md="6">
                            <div class="d-flex align-center justify-center h-100">
                                <v-btn color="info" @click="redirect(technicalEvaluation.uuid)">
                                    Ir para a avaliação
                                    <v-icon class="ml-2">mdi-flag-checkered</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col order="3" order-sm="3" order-md="3" cols="12">
                            <h3 class="font-weight-medium">Colaboradores</h3>
                            <v-divider></v-divider>
                            <v-list class="pt-0 overflow-y-auto" height="20em">
                                <v-list-item v-for="(subordinado, i) in technicalEvaluation.subordinados" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-medium">{{ subordinado }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'

export default {
    name: 'ListaAvaliacoesTecnica',
    setup() {
        // Variables
        const loading = ref(true);
        const { route } = useRouter();

        // Computed
        const technicalEvaluations = computed(() => store.getters['avdFormularios/getTechnicalEvaluations'])

        // LifeCycle
        onMounted(() => {

            const data = { batteryUuid: route.value.params.battery_uuid, managerUuid: route.value.params.uuid }

            store.dispatch('avdFormularios/getTechnicalEvaluations', data).finally(() => {
                loading.value = false;
            })
        })

        // Function
        function redirect(metaUuid) {
            const params = { uuid: route.value.params.uuid, battery_uuid: route.value.params.battery_uuid, meta_uuid: metaUuid }

            this.$router.push({ name: 'avd-avaliacao-tecnica-ext', params: params })
        }

        return {
            // Variables
            loading,

            // Computed
            technicalEvaluations,

            // Function
            redirect
        }
    }
}
</script>

<style scoped>
.h-100 {
    height: 100% !important;
}
</style>